<template>
   <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
      <div class="card-body p-12" v-if="tipoVisualizacao == 'Lojas'">
         <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ carga.nome }}</h1>
         <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ carga.id }}</p>
         <p class="font-12 mb-0 limitador-2">
            <i class="far fa-dolly color-theme font-10 me-1"></i><strong class="subtitle me-1"> Versão atual:</strong>
            <span class="weight-500" :class="(parseInt(cargaAtual) - parseInt(carga.versaoCarga)) <= 0 ? 'text-green' : 'text-red'">{{ carga.versaoCarga }}</span>
            <small class="font-12 text-secondary" v-if="(parseInt(cargaAtual) - parseInt(carga.versaoCarga)) > 0"> ({{ parseInt(cargaAtual) - parseInt(carga.versaoCarga) }} faltando)</small>
         </p>
      </div>
      <div class="card-body p-12 cursor-pointer" v-else @click="buscarProdutos">
         <h1 class="font-15 mb-0 limitador-1 text-uppercase">Carga nº {{ carga.numero == null ? '-' : carga.numero }}</h1>
         <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ carga.id }}</p>
         <p class="font-12 mb-0 limitador-2">
            <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1"> Data:</strong>
            <span>{{ carga.data.dayOfMonth.toString().padStart(2, '0') +'/'+ carga.data.monthValue.toString().padStart(2, '0') +'/'+ carga.data.year }} </span>
            <span>{{ carga.data.hour.toString().padStart(2, '0') +':'+ carga.data.minute.toString().padStart(2, '0') }}</span>   
         </p>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Carga',
   props: ['carga', 'index', 'tipoVisualizacao', 'cargaAtual'],
   methods: {
      buscarProdutos : function () {
         this.$emit('buscarProdutos', {'id': this.carga.numero})
      }
   }
}

</script>