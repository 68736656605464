<template>
   <div class="card mb-1">
		<div class="card-body p-12">
         <!-- Cabecalho -->
         <div class="row">
            <div class="col mb-2 align-self-center">
               <div class="limitador-1 mb-1">
                  <i class="far fa-store color-theme font-13 me-1"></i><strong class="subtitle font-15 text-capitalize"> {{ loja.nomeLoja }}</strong>
                  <small class="mx-2">|</small><span class="font-13"><i class="far fa-tag font-11 me-1"></i> {{ loja.numeroLoja }}</span>
               </div>
            </div>
            <div class="w-max-content mb-2 d-none d-xl-block">
               <a href="javascript:;" class="badge badge-default-outline mb-1 cursor-auto">
                  <i class="far fa-info-circle me-1"></i> Versão da carga mais recente: nº {{ loja.cargaAtual }}
					</a>
            </div>
            <div class="w-max-content mb-2 d-none d-sm-block">
               <a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="cargaBalanca"><i class="far fa-download font-10 me-1"></i> Carga balança</a>
            </div>
            <div class="w-max-content mb-2">
               <a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
            </div>
         </div>

         <!-- Principal -->
			<div class="row">
            <carga v-for="(carga, index) in loja.pdvs" :key="index" :index="index" :carga="carga" :tipoVisualizacao="'Lojas'" :cargaAtual="loja.cargaAtual" />
            <div class="col-12 my-5 text-center" v-if="loja.pdvs.length == 0">
               <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
               <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum caixa encontrado</h4>
            </div>
			</div>
		</div>
	</div>
</template>

<script>

import carga from '@/components/automacao/cargas/Carga.vue'

export default {
	name: 'Loja',
	props: ['loja', 'index'],
   components: {
      carga
   },
   methods: {
      exportar : function () {
         this.$store.dispatch('exportarExcel', {
            'nome': ('Carga atual - '+ this.loja.nomeLoja),
            'resultado': this.loja.pdvs, 
            'campos': ['numeroLoja', 'nomeLoja', 'cargaAtual'], 
            'lista': {'nome': 'pdvs', 'campos': ['nome', 'ip', 'versaoCarga']}
         })
      },
      cargaBalanca : function () {
         this.$emit('cargaBalanca', this.loja.idLoja)
      }
   }
}

</script>