<template>
   <div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Gere cargas de produtos para posteriormente tê-los em seu PDV offline. Apenas produtos com a tributação preenchida são considerados.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-lg-5 mb-3">
                     <label><i class="far fa-eye color-theme font-11 me-1"></i> Tipo visualização</label>
                     <v-select name="Desktop" :options="['Histórico', 'Loja']" v-model="pesquisa.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
							<select name="Mobile" class="form-control" v-model="pesquisa.tipo">
								<option v-for="(option, index) in ['Histórico', 'Loja']" :key="index" :value="option">{{ option }}</option>
							</select>
                  </div>
                  <div class="col-sm-6 col-lg-4 mb-3" v-if="pesquisa.tipo == 'Loja'">
                     <label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
                     <v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
                        <template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
                        <template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
                     </v-select>
                     <select name="Mobile" class="form-control" v-model="pesquisa.loja">
                        <option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-4 mb-3" v-else>
                     <label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
                     <datePicker format="DD/MM/YYYY" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="col-4 col-sm-6 col-lg-1 mb-3 align-self-end text-center">
                     <button type="button" class="btn btn-primary w-100" @click="buscarCargas"><i class="fas fa-search font-13"></i></button>
                  </div>
                  <div class="col-8 col-sm-6 col-lg-2 mb-3 align-self-end text-center">
                     <button type="button" class="btn btn-primary w-100" @click="buscarProdutos($options.data.apply(this).modal)"><i class="far fa-box font-11 me-1"></i> Próxima carga</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.tipo == 'Loja'">
         <loja v-for="(loja, index) in pesquisa.retorno.resultado" :key="index" :loja="loja" :index="index" @cargaBalanca="gerarCargaBalanca($event)" />
      </div>

      <div class="col-12" v-else-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<carga v-for="(carga, index) in pesquisa.retorno.resultado" :key="index" :carga="carga" :index="index" :tipoVisualizacao="'Cargas'" 
                     @buscarProdutos="buscarProdutos($event, false)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalProdutos -->
      <div class="modal fade" id="modalProdutos" tabindex="-1" aria-labelledby="modalProdutosLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalProdutosLabel">Produtos - Carga {{ modal.carga == null ? 'atual' : 'nº '+ modal.carga }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row">
                     <produto v-for="(produto, index) in modal.resultado" :key="index" :produto="produto" :index="index" />
                     
                     <div class="col-12 my-5 text-center" v-if="modal.resultado.length == 0">
                        <i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
                        <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
                     </div>

                     <div class="col-12 mt-3 mb-2 text-center" v-else-if="modal.resultado.length % 30 == 0">
                        <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarProdutos(null, false)">Ver mais</button>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" v-if="modal.carga == null" @click="gerarCarga">Gerar carga</button>
                  <button type="button" class="btn btn-primary" v-else-if="modal.resultado.length > 0" @click="buscarProdutos(null, true)">Exportar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import loja from '@/components/automacao/cargas/Loja.vue'
import carga from '@/components/automacao/cargas/Carga.vue'
import produto from '@/components/automacao/cargas/Produto.vue'

export default {
	name: 'Cargas',
	data: function () {
      return {
         pesquisa: {'tipo': 'Histórico', 'loja': null, 'data': new Date(), 'retorno': {'tipo': null, 'loja': null, 'data': null, 'resultado': []}},
			modal: {'carga': null, 'pagina': 0, 'resultado': []},
         listaExportacao: []
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
			if (!(value instanceof Date)) {
				this.pesquisa.data = new Date(value)
			}
      }
	},
	components: {
      loja, carga, produto
   },
	methods: {
      exportar : function () {
         this.$store.dispatch('exportarExcel', {
            'nome': ('Histórico de cargas - '+ this.pesquisa.retorno.data == null ? 'Sem data' : this.pesquisa.retorno.data.dayOfMonth.toString().padStart(2, '0') +'-'+ this.pesquisa.retorno.data.monthValue.toString().padStart(2, '0')),
            'resultado': this.pesquisa.retorno.resultado, 
            'campos': ['numero', 'data'], 
            'lista': null
         })
      },
		buscarCargas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

         if (this.pesquisa.loja == null) this.pesquisa.loja = 0;

			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/searchCargas',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		buscarProdutos : function (objPdv, isExcel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal = {
				'carga': objPdv != null ? objPdv.id : this.modal.carga,
				'pagina': isExcel ? -1 : (objPdv != null ? 0 : this.modal.pagina),
				'resultado': objPdv != null ? [] : this.modal.resultado
			}

			$('#modalProdutos').modal('show')
         Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

         this.$axios({
            method: 'post',
            url: this.urlRest +'produtos/getProdutosCarga',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

         }).then(response => {
				if (isExcel) {
               this.listaExportacao = response.data
            } else {
               this.modal.pagina++;
               this.modal.resultado.push(... response.data)
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)

            if (isExcel) {
               this.$store.dispatch('exportarExcel', {
                  'nome': 'Produtos carga '+ this.modal.carga,
                  'resultado': this.listaExportacao, 
                  'campos': ['id', 'nome', 'codigos'], 
                  'lista': {'nome': 'precos', 'campos': ['numeroLoja', 'nomeLoja', 'preco']}
               })
            }
         });
      },
      gerarCarga : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Gerar carga?',
            text: 'Os produtos alterados estarão disponíveis para envio aos seus caixas offline.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'produtos/gerarCarga'
                  
               }).then(() => {
                  $('#modalProdutos').modal('hide')

                  Swal.fire({
                     icon: 'success',
                     title: 'Carga gerada!',
                     text: 'Em breve os produtos serão enviados para seus caixas offline.',
                     showConfirmButton: false,
                     showCancelButton: true,
                     cancelButtonText: `Fechar`
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
      gerarCargaBalanca : function (idLoja) {
         Swal.fire({
            icon: 'warning',
            title: 'Gerar carga para balança?',
            text: 'O sistema fará o download de um arquivo para ser utilizado no programa da balança.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'get',
                  url: this.urlRest +'produtos/gerarCargaBalanca',
						responseType: 'blob',
                  params: {
							idLoja: idLoja
						}
               }).then((response) => {
                  let link = document.createElement('a')
						let data = window.URL.createObjectURL(response.data)
						link.href = data
						link.download = "itens.txt"
						link.click()

						setTimeout(() => {
							window.URL.revokeObjectURL(data);
						}, 100)
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
	},
   mounted() {
      Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
   }
}

</script>